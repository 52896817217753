.theHeroSection {
  width: 100%;
  height: auto;
}

.imageParent {
  width: 100%;
  position: relative;
  height: max-content;
  background-color:rgba(0, 0, 0, 0.87);
}

@font-face {
  font-family: "CCUpUpAndAway";
  src: local("CCUpUpAndAway"), local("CCUpUp-AndAway"),
    url("./Images/CCUpUpAndAway.otf") format("truetype");
}

.CC{
  font-family: "CCUpUpAndAway";
}

@font-face {
  font-family: "Something Light";
  src: local("Something Light"), local("Something-Light"),
    url("./Images/DINNextLTPro-LightCondensed.ttf") format("truetype");
}

.SL {
  font-family: "Something Light";
}

@font-face {
  font-family: "Something Condensed";
  src: local("Something Condensed"), local("Something-Condensed"),
    url("./Images/DINNextLTPro-Condensed.ttf") format("truetype");
}

.SC {
  font-family: "Something Condensed";
}

@font-face {
  font-family: "Something";
  src: local("Something"), local("Something"),
    url("./Images/GothamBook.ttf") format("truetype");
}

.S {
  font-family: "Something";
}

@font-face {
  font-family: "Condensed";
  src: local("Condensed"), local("Condensed"),
    url("./Images/FRADM.TTF") format("truetype");
}

.C {
  font-family: "Condensed";
}

@font-face {
  font-family: "Meddumm";
  src: local("Meddumm"), local("Meddummd"),
    url("./Images/Gotham\ Medium.otf") format("truetype");
}

.M {
  font-family: "Meddumm";
}

.linker1FB {
  position: fixed;
  width: 4%;
  height: 3%;
  bottom: 3vw;
  left: 80%;
}

.linker2Istg {
  position: absolute;
  width: 4%;
  height: 3%;
  bottom: 2vw;
  left: 48%;
}

.linker3YTB {
  position: absolute;
  width: 4%;
  height: 3%;
  bottom: 2vw;
  left: 54%;
}

.cp{
  cursor: pointer;
}

.secondSection {
  background: linear-gradient(rgba(0, 0, 0, 0.87), rgba(0, 0, 0, 0.87)), 
                  url('./Images/finallyBcg.png') no-repeat center center / cover;
  display: flex;
  flex-direction: column;
  padding-top: 10vw;
  padding-left: 8vw;

}

.textStory , .textPreview, .textPlayer{
  color: white;
  font-size: 12vw;
  margin-bottom: 2vw;
}

.storySection {
  display: flex;
  margin-bottom: 15vw;
}

.img img{
  width: 34vw;
}

.summarry {
  display: flex;
  flex-direction: column;
  gap: 1vw;
  margin-right: 6vw;
  font-size: 1.6vw;
  line-height: 1.8vw;
  margin-left: 1vw;
  color: white;
}

.prviewSection{
  display: flex;
  margin-top: 2vw;
  gap: 2vw;
  flex-wrap: wrap;
  margin-bottom: 15vw;
}

.prviewSection iframe {
  width: 40vw;
  height: 22vw;
  /* margin-bottom: 15vw; */
}

.playerSection {
  display: flex;
  margin-bottom: 10vw;
}

.playerSection .sect1 , .playerSection .sect2 {
  display: flex;
  flex-direction: column;
  gap: 1vw;
}

.people {
  display: flex;
}

.people .imgCont {
  display: flex;
  flex-direction: column;
}

.people .imgCont img:nth-child(1){
  width: 10vw;
  height: 10vw;
  object-fit: cover;
}

.people .imgCont img:nth-child(2){
  width: 3vw;
  height: 2vw;
  object-fit: cover;
}

.textContainer {
  color: white;
  display: flex;
  flex-direction: column;
  font-size: 1vw;
  margin-left: 1vw;
  font-family: "Something Light";
  word-spacing: 2px;
  width: 54%;
}

.titleContainer {
  display: flex;
  gap: 0.3vw;
  margin-bottom: 0.3vw;

}

.title {
  font-family: Condensed;
}




@media (max-width: 450px) {
  .storySection {
    display: flex;
    margin-bottom: 15vw;
    flex-direction: column;
}

.img img {
  width: 85%;
}

.textStory, .textPreview, .textPlayer {
  color: white;
  font-size: 15vw;
  margin-bottom: 2vw;
}

.summarry {
  display: flex;
  flex-direction: column;
  gap: 1vw;
  margin-right: 6vw;
  font-size: 2.6vw;
  line-height: 2.8vw;
  margin-left: 0;
  color: white;
  margin-top: 2vw;
}

.prviewSection {
  display: flex;
  margin-top: 2vw;
  gap: 2vw;
  flex-direction: column;
  margin-bottom: 10vw;
}

.prviewSection iframe {
  width: 70vw !important;
  height: 58vw !important;
  margin-bottom: 5vw !important;
}

.playerSection {
  display: flex;
  margin-bottom: 10vw;
  flex-direction: column;
  gap: 5vw;
}

.sect1 , .sect2 {
  gap: 5vw !important;
}

.people .imgCont img:nth-child(1) {
  width: 20vw;
  height: 20vw;
  object-fit: cover;
}

.people .imgCont img:nth-child(2) {
  width: 6vw;
  height: 5vw;
  object-fit: cover;
}

.textContainer {
  color: white;
  display: flex;
  flex-direction: column;
  font-size: 2.5vw;
  margin-left: 1vw;
  font-family: "Something Light";
  word-spacing: 2px;
  width: 73%;
  margin-left: 2vw;
}


}

.fixedIconSwage {
  position: fixed;
  right: 2vw;
  bottom: -3vw;  
  z-index: 222;
}

.fixedIconSwage img {
  width: 16vw;
  height: 12vw;
}

.linker1FB {
  position: fixed;
  width: 3%;
  height: 7%;
  bottom: 2vw;
  left: 83%;
}

.linker2Istg {
  position: fixed;
  width: 3%;
  height: 7%;
  bottom: 2vw;
  left: 88.5%;
}

.linker3YTB {
  position: fixed;
  width: 3%;
  height: 7%;
  bottom: 2vw;
  left: 93.5%;
}